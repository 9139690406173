.app-name {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 13px;
  text-align: center;
}

.wrapper {
  height: 100%;
}

input[type='checkbox'] {
  width: 14px !important;
  height: 14px !important;
  margin: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 1px solid gray;
  box-shadow: none;
  font-size: 0.8em;
  text-align: center;
  line-height: 1em;
  background: rgb(223, 212, 212);
}

input[type='checkbox']:checked:after {
  content: '✔';
  color: white;
}

input[type='checkbox']:checked {
  background: rgb(13, 124, 228);
}

.react-tel-input .form-control {
  color: inherit !important;
  border: 0px solid white !important;
  background: rgba(65, 124, 241, 0.05) !important;
  transition: all 0.3s ease-in-out !important;
  border-radius: 12px !important;
  background-color: rgba(65, 124, 241, 0.05) !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  width: 100% !important;
  color: red !important;
}

.react-tel-input .form-control:hover {
  background-color: rgba(65, 124, 241, 0.15) !important;
}
.react-tel-input .form-control:focus {
  outline: none !important;
  background: radial-gradient(
    circle,
    rgba(35, 0, 235, 0.16) 0%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  background-color: rgba(65, 124, 241, 0.05) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.walletGraphCard {
  padding: 16px;
}
.walletGraphCardTitle {
  font-size: 1.5rem !important;
}
.walletGraphCardDescription {
  width: 33.333333%;
}
@media only screen and (max-width: 1225px) and (min-width: 691px) {
  .walletGraphCardTitle {
    font-size: 17px !important;
  }
  .walletGraphCard {
    padding: 8px;
  }
  .walletGraphCardDescription {
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  .walletGraphCardDescription {
    width: 60% !important;
  }
}
